import React, { useState, useEffect } from "react";
import {
  Card,
  Select,
  Button,
  message,
  Table,
  Typography,
  Form,
  Space,
} from "antd";
import {
  getTeams,
  getActiveDatabaseForTeam,
  setActiveDatabaseForTeam,
  fetchDatabases,
  getUnassignedCounts,
} from "../../services/apiMethods";

import TeamDatabaseCount from "./TeamDatabaseCount";
import DatabaseReassignment from "./DatabaseReassignment";

const { Text } = Typography;
const { Option } = Select;

const TeamDatabaseManager = () => {
  const [teams, setTeams] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [activeDatabaseMap, setActiveDatabaseMap] = useState({});
  const [unassignedCounts, setUnassignedCounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const teamData = await getTeams();
        setTeams(teamData);

        const activeDatabases = {};
        const teamDatabases = {};

        for (const team of teamData) {
          const active = await getActiveDatabaseForTeam(team.name);
          activeDatabases[team.name] = active?.activeSource || null;

          const availableDatabases = await fetchDatabases(team.name);
          teamDatabases[team.name] = availableDatabases;
        }

        const unassignedCounts = await getUnassignedCounts();
        setUnassignedCounts(unassignedCounts);

        setActiveDatabaseMap(activeDatabases);
        setDatabases(teamDatabases);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
        message.error("Ошибка загрузки данных");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleSaveActiveDatabase = async (teamName, selectedDatabase) => {
    try {
      await setActiveDatabaseForTeam(teamName, selectedDatabase);
      setActiveDatabaseMap((prev) => ({
        ...prev,
        [teamName]: selectedDatabase,
      }));
      message.success(`Активная база для команды "${teamName}" обновлена.`);
    } catch (error) {
      console.error("Ошибка при сохранении активной базы:", error);
      message.error(
        `Ошибка при сохранении активной базы для команды "${teamName}".`
      );
    }
  };

  const getFilteredDatabasesForTeam = (teamName) => {
    const teamDatabases = databases[teamName] || [];
    const unassignedData = unassignedCounts.filter(
      (db) => db.team === teamName && db.unassignedCount > 0
    );

    const filteredDatabases = teamDatabases.filter((db) =>
      unassignedData.some((unassigned) => unassigned.source === db)
    );

    return filteredDatabases;
  };

  const columns = [
    {
      title: "Команда",
      dataIndex: "name",
      key: "name",
      render: (name) => <Text strong>{name}</Text>,
    },
    {
      title: "Текущая активная база",
      dataIndex: "name",
      key: "activeDatabase",
      render: (teamName) => (
        <Text>{activeDatabaseMap[teamName] || "Без базы"}</Text>
      ),
    },
    {
      title: "Управление",
      dataIndex: "name",
      key: "management",
      render: (teamName) => (
        <Form
          layout="inline"
          onFinish={({ database }) =>
            handleSaveActiveDatabase(teamName, database)
          }
          initialValues={{
            database: activeDatabaseMap[teamName],
          }}
        >
          <Form.Item
            name="database"
            rules={[{ required: true, message: "Выберите базу" }]}
          >
            <Select
              placeholder="Выберите базу"
              style={{ width: 200 }}
              loading={isLoading}
            >
              {getFilteredDatabasesForTeam(teamName).map((db) => (
                <Option key={db} value={db}>
                  {db}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <Card title="Управление базами команд" loading={isLoading}>
      <Table
        dataSource={teams.map((team) => ({ ...team, key: team.id }))}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      <TeamDatabaseCount />
      <DatabaseReassignment />
    </Card>
  );
};

export default TeamDatabaseManager;
